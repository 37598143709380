.classA-component {
  width: 100%;
  height: 100%;
  position: relative;
  margin: 0 auto;

  .bg-transparent {
    background-color: transparent !important;
  }

  .bg-fff {
    background-color: #ffffff !important;
  }

  .classA-component-content {
    display: flex;
    justify-content: space-between;
    width: 100%;
    height: 100%;
    // min-width: 1200px;
    // max-width: 1600px;
    margin: 0 auto;

    .classA-component-left {
      position: relative;
      width: 163px;
      background: #ffffff;
      box-shadow: 0px 0px 16px 0px #c9d6ed, inset 0px 1px 1px 1px rgba(255, 255, 255, 0.6);
      border-radius: 8px;
      box-sizing: border-box;
      padding: 12px 0 0;

      &:hover {
        .sub-class-list {
          display: block;
        }
      }

      .class-list-main-title {
        font-size: 16px;
        font-weight: 500;
        color: #333333;
        line-height: 16px;
        padding: 4px 16px 6px;
      }

      .class-list-item {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        height: 34px;
        font-size: 12px;
        color: #333333;
        line-height: 34px;
        cursor: pointer;

        .class-list-item-title {
          width: 100%;
          padding: 0 16px;
          // text-align: center;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
        }

        .active {
          color: #2c6bff !important;
        }

        &:hover {
          color: #2c6bff;
        }
      }

      .sub-class-list {
        display: none;
        position: absolute;
        top: 0;
        left: 163px;
        width: 926px;
        height: 80%;
        padding: 12px 16px;
        background: #ffffff;
        box-shadow: 0px 0px 16px 0px #c9d6ed, inset 0px 1px 1px 1px rgba(255, 255, 255, 0.6);
        border-radius: 0px 8px 8px 0px;
        z-index: 11;

        .sub-class-list-item {
          display: flex;
          justify-content: space-between;
          margin-bottom: 8px;

          .sub-class-item-left {
            display: flex;
            justify-content: space-between;
            align-items: center;
            height: 25px;
            margin-right: 16px;
            min-width: 76px;
            font-size: 12px;
            color: #333333;
            line-height: 25px;
            font-weight: 500;
            cursor: pointer;

            .sub-class-item-left-text {
              width: 60px;
              white-space: nowrap;
              overflow: hidden;
              text-overflow: ellipsis;

              &:hover {
                color: #2c6bff;
              }
            }
          }

          .sub-class-item-right {
            display: flex;
            flex: 1;
            flex-wrap: wrap;
            justify-content: flex-start;
            font-size: 12px;
            color: #777777;
            line-height: 25px;

            .sub-class-item-right-item {
              margin-right: 16px;
              cursor: pointer;

              &:hover {
                color: #2c6bff;
              }
            }
          }
        }
      }
    }

    .classA-component-center {
      display: flex;
      flex-direction: column;
      width: 65%;
      margin: 0 16px;
      flex: 1;

      .center-search {
        display: flex;
        height: 48px;
        margin-bottom: 16px;

        .header-search {
          display: flex;
          justify-content: center;
          align-items: center;
          box-sizing: border-box;
          flex: 1;
          height: 48px;
          padding: 4px 0;
          border-radius: 8px;
          background-color: #fff;
          box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);

          .anticon {
            color: rgba(0, 0, 0, 0.4);
            padding: 0 6px 0 16px;
          }

          .header-search-btn {
            display: flex;
            align-items: center;
            justify-content: center;
            box-sizing: border-box;
            flex-shrink: 0;
            width: 112px;
            height: 48px;
            border-radius: 0px 8px 8px 0px;
            font-size: 18px;
            background: #2c6bff;
            color: #ffffff;
            cursor: pointer;
          }
        }

        .header-cart {
          display: flex;
          align-items: center;
          justify-content: center;
          width: 184px;
          height: 48px;
          margin-left: 16px;
          background: #ffffff;
          border-radius: 8px;
          border: 1px solid #2c6bff;
          font-size: 18px;
          font-family: AlibabaPuHuiTi_2_65_Medium;
          color: #2c6bff;
          cursor: pointer;
        }
      }

      .center-banner {
        flex: 1;
        background: #e7e9ec;
        border-radius: 8px;

        .preview-modal-wrapper {
          position: relative;
          border-radius: 8px !important;

          .slick-list {
            border-radius: 8px !important;
          }

          .preview-dot {
            li {
              width: 24px;
              height: 6px !important;
              border-radius: 3px;
              background-color: rgba(255, 255, 255, 0.5);

              button {
                height: 6px !important;
              }
            }

            li.slick-active button {
              background-color: #ffffff !important;
            }
          }

          .preview-modal-item {
            width: 100%;
            height: 100%;
            line-height: 0;
            cursor: pointer;
          }
        }
      }
    }

    .classA-component-right {
      display: flex;
      flex-direction: column;
      width: 21%;

      .right-item {
        flex: 1;
        padding-top: 51%;
        margin-bottom: 16px;
        background-color: #e7e9ec;
        border-radius: 8px !important;
        background-repeat: no-repeat;
        background-size: 100% 100%;
        cursor: pointer;

        &:last-child {
          margin-bottom: 0;
        }
      }
    }
  }
}

.zl-footer-container {
  min-height: 180px;
  background-color: #262626;

  .zl-footer-box {
    display: flex;
    justify-content: space-between;
    padding-top: 40px;
    padding-bottom: 20px;
    width: 1200px;
    margin: 0 auto;
  }
  .zl-bottom-text {
    text-align: center;

    font-weight: 400;
    font-size: 12px;
    color: #777777;
    line-height: 16px;
  }
}
.zl-info-title {
  font-weight: 500;
  font-size: 14px;
  color: #ffffff;
  line-height: 20px;
  text-align: left;
  font-style: normal;
}
.zl-info-text {
  font-weight: 400;
  font-size: 12px;
  color: #aaaaaa;
  line-height: 16px;
  margin-top: 10px;
}
.zl-contactInfo-image {
  display: flex;
  margin-top: 12px;
}
.zl-contact-info-title {
  font-weight: 500;
  font-size: 14px;
  color: #ffffff;
  margin-top: 8px;
}
.zl-image-container {
  display: flex;
  flex-direction: column;
  align-items: center;
}

@primary-color: #2c68ff;@shadow-1-down: 0 6px 16px -8px rgba(0, 0, 0, 0.08), 0 9px 28px 0 rgba(0, 0, 0, 0.05),
  0 12px 48px 16px rgba(0, 0, 0, 0.03);@screen-xs: 480px;@screen-xs-min: @screen-xs;@screen-sm: 576px;@screen-sm-min: @screen-sm;@screen-md: 768px;@screen-md-min: @screen-md;@screen-lg: 992px;@screen-lg-min: @screen-lg;@screen-xl: 1200px;@screen-xl-min: @screen-xl;@screen-xxl: 1600px;@screen-xxl-min: @screen-xxl;