.common-header-component {
  // width: 100%;
  height: 32px;
  line-height: 32px;
  position: relative;
  margin: 0 auto;
  background-color: #262626;

  .bg-transparent {
    background-color: transparent !important;
  }

  .bg-fff {
    background-color: #ffffff !important;
  }
}
.showroom-common-headers {
  width: 1200px;
  margin: 0 auto;
}
.common-header-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  .common-header-title {
    color: #ffffff;
  }
  .header-info-container {
    display: flex;
    .header-info-item {
      cursor: pointer;
      color: #ffffff;
    }
    // .header-info-item:hover {
    //   cursor: pointer;
    //   color: #2c6bff;
    // }
  }
}

@primary-color: #2c68ff;@shadow-1-down: 0 6px 16px -8px rgba(0, 0, 0, 0.08), 0 9px 28px 0 rgba(0, 0, 0, 0.05),
  0 12px 48px 16px rgba(0, 0, 0, 0.03);@screen-xs: 480px;@screen-xs-min: @screen-xs;@screen-sm: 576px;@screen-sm-min: @screen-sm;@screen-md: 768px;@screen-md-min: @screen-md;@screen-lg: 992px;@screen-lg-min: @screen-lg;@screen-xl: 1200px;@screen-xl-min: @screen-xl;@screen-xxl: 1600px;@screen-xxl-min: @screen-xxl;