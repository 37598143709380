#root {
  background: #f2f5f9;
}
body {
  background: #f2f5f9;
}

.fle-pro-layout-bg-list,
.fle-menu-sider {
  position: unset !important;
}
.fle-layout {
  background: unset !important;
  // max-width: 1200px;
  margin: 0 auto;
}
.fle-layout-content {
  // width: calc(100% - 8px);
  height: 100%;
  background-color: #fff !important;
  // margin: 0 16px;
  // margin-left: 8px;
}
